/* 🔥 Main About Section */
.animated-bg-about {
  font-size: 24px;
      /* background:  radial-gradient(circle farthest-corner at 100% 100%,  #2c6cbc 10% , #71c3f7 40% , #f6f6f6 50%); */
      /* linear-gradient(to right,  #71c3f7, #f6f6f6); */
      /* background: linear-gradient(to bottom, #71c3f7, #f6f6f6); */
    background-color:white ;
  padding: 70px 50px;
  /* background: linear-gradient(to right, #71c3f7 5%, #f6f6f6 95%); */

}

/* 🔥 Container */
.about-section {
  display: flex;
  flex-direction: column;
  gap: 70px;
  width: 100%;
  /* padding: 0 10%; */
  /* margin-top: 30px; */
}

/* 🔥 First Row - Image Left, Title & Vision/Mission Right */
.about-top-row {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Image left, Content right */
  align-items: center;
  /* gap: 50px; */
  width: 100%;
}


/* 🔥 Image Container - Bigger Image */
.about-image-container {
  width: 100%; /* Ensure it takes full width */
  display: flex;
  justify-content: center; /* Centering the image */
  max-width: 700px; /* Ensures a large, balanced image */
    margin: 0 auto;
}

.about-image-container img {
  width: 100%; /* Ensure image fills container */
  max-width: 500px;
  height: auto;
    border-radius: 50px;
  transition: transform 0.3s ease-in-out;
}

.about-image-container:hover img {
  transform: scale(1.05);
}

/* 🔥 Right Content */
.about-right-content {
  flex: 1;
}

/* 🔥 Heading */
.about-heading {
  right: 15%;
  font-size: 50px;
  font-weight: bold;
  color: #134084;
  font-family: sans-serif;
  text-align: center;
  margin-bottom: 50px;
  position: relative;
}


/* 🔥 Vision & Mission */
.vision-mission {
  display: flex;
  flex-direction: column; /* Vertical by default */
  gap: 40px;
  align-items: center;
  text-align: center;
}

/* 🔥 Individual Items */
.vision,
.mission {
  display: flex;
  flex-direction: row; /* Icon on left, text on right */
  align-items: center;
  text-align: left;  
  gap: 20px;
  max-width: 700px;
}

/* 🔥 Text Adjustments */
.text-content h3 {
  font-size: 26px;
  color: #134084;
  margin-bottom: 5px;
}

.text-content p {
  font-size: 18px;
  color: #555;
  max-width: 500px;
}/* 🔥 Icons */
.icon {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #134084;
}

/* 🔥 Second Row - Stats (Left) & Extra Content (Right) */
.about-bottom-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
}

/* 🔥 Stats Section */
.stats-container {
  display: flex;
  gap: 40px;
  width: 50%;
}

/* 🔥 Cards (Fixed Stack Design) */
.stats-card {
  position: relative;
  width: 220px;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stack {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 15px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.stack-2 {
  top: 12px;
  left: 12px;
}

.stack-1 {
  top: 6px;
  left: 6px;
}

/* 🔥 Card Content */
.content {
  position: relative;
  background: white;
  border-radius: 15px;
  /* padding: 25px; */
  width: 100%;
  height: 100%;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.content h2 {
  font-size: 30px;
  font-weight: bold;
  color: #003366;
  margin-bottom: 5px;
}

.content h2 span {
  font-size: 42px;
}

.content p {
  font-size: 16px;
  font-weight: bold;
  color: #d9534f;
  text-transform: uppercase;
}

/* 🔥 Extra Content */
.about-extra-content {
  flex: 1;
  /* padding: 20px; */
  text-align: left;
}

.about-extra-content h2 {
  font-size: 28px;
  color: #134084;
  margin-bottom: 10px;
}

.about-extra-content p {
  font-size: 18px;
  color: #555;
}

/* 🔥 Responsive for Tablets & Small Screens */
@media screen and (max-width: 1024px) {
  .animated-bg-about {
    padding: 60px 40px !important;
  }

  .about-top-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .about-heading {
    order: 1; /* Heading appears first */
    font-size: 42px;
    margin-bottom: 30px;
    right: 0%;
  }
  .about-image-container {
    order: 2; /* Image comes after heading */
    width: 100%;
    display: flex;
    max-width: 600px;
    justify-content: center; /* Center image properly */
  }

  .about-image-container img {
    width: 85%; /* Ensures better centering */
    max-width: 600px;

  }
/* 🔥 Vision & Mission - Now Vertical */
.vision-mission {
  order: 3; /* Vision & Mission appears last */
  flex-direction: column;
  gap: 30px;
}

.vision,
.mission {
  flex-direction: column; /* Stack items */
  text-align: center;
  align-items: center;
}
  .about-bottom-row {
    flex-direction: column;
    text-align: center;
  }

  .stats-container {
    display: flex;
    /* flex-direction: column; */
    gap: 40px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .about-extra-content {
    order: 5;
    text-align: center;
  }
}

@media screen and (max-width: 1400px) {
  

  .about-bottom-row {
    flex-direction: column;
    text-align: center;
  }

  .stats-container {
    /* display: flex; */
    /* flex-direction: column; */
    gap: 40px;
    width: 100;
    width: 100%;
    align-items: center;
    justify-content: center;
    order: 2; /* Image comes after heading */

  }
  .about-extra-content {
    order: 1;
    text-align: center;
  }
}

/* 🔥 Responsive for Mobile */
@media screen and (max-width: 768px) {
  .about-section {
    /* padding: 0 20px; */
  }

  .about-heading {
    font-size: 38px;
}

.about-image-container {
    /* max-width: 100%; */
    height: auto;
    max-width: 500px;

}
.about-image-container img{
  /* max-width: 100%; */
  height: auto;
  max-width: 500px;

}
  
  .about-bottom-row {
    flex-direction: column;
  }

  .stats-container {
    /* flex-direction: column; */
    align-items: center;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .stats-card {
    width: 200px;
    height: 160px;
  }
}

@media screen and (max-width: 600px) {
  
  .stats-container {
    flex-direction: column;
    gap: 20px;
    width: 100%;
    width: 100%;
    /* align-items: center; */
    justify-content: center;
  }

  /* .stats-card {
    width: 200px;
    height: 160px;
  } */
  .about-image-container {
    /* max-width: 100%; */
    width: 100%;
    max-width: 450px;
}
.about-image-container img{
  /* max-width: 100%; */
  width: 100%;
        max-width: 450px;
}
  
}

@media screen and (max-width: 480px) {
  .about-heading {
      font-size: 32px;
  }

  .vision-mission {
      gap: 20px;
  }

  .text-content h3 {
      font-size: 24px;
  }

  .text-content p {
      font-size: 16px;
  }

  
}
