/* 🔥 Features Section */
.features-section {
    text-align: center;
    padding: 80px 10%;
    /* background-color:#f5f5f5 ; */
    /* background: linear-gradient(to top, #71c3f7, #f6f6f6); */
    background: rgba(239, 239, 239, 0.9); /* 0.9 reduces transparency */

}

/* 🔥 Section Heading */
.features-heading {
    font-size: 40px;
    font-weight: bold;
    color: #134084;
    margin-bottom: 40px;
}

/* 🔥 Feature Row */
.features-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center items dynamically */
    gap: 60px;
    margin-bottom: 40px;
}

/* 🔥 Feature Item */
.feature-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 130px; /* Fixed width for uniform spacing */
}

/* 🔥 Feature Icon */
.feature-icon {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    mix-blend-mode: multiply; /* Removes white background */
    background-color: transparent;
    border-radius: 50%; /* Makes it a perfect circle */
    border: 3px solid #134084; /* Circular border */

}

/* 🔥 SVG Icon Styling */
.feature-icon img {
    width: 60px;
    height: 60px;
}

/* 🔥 Feature Title */
.feature-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
}

/* 🔥 Responsive Adjustments */
@media screen and (max-width: 1120px) {
    .features-row {
        justify-content: center; /* Center all items */
    }
    .feature-item {
        flex: 0 1 calc(30% - 20px); /* 3 items in a row */
    }
    .features-heading {
        font-size: 35px;
        font-weight: bold;
        color: #134084;
        margin-bottom: 40px;
    }
    
}

/* @media screen and (max-width: 900px) {
    .feature-item {
        flex: 0 1 calc(33.33% - 20px); 
    }
} */

@media screen and (max-width: 700px) {
    .feature-item {
        flex: 0 1 calc(50% - 20px); /* First row: 2 items */
    }
    .features-row:last-child {
        justify-content: center; /* Ensures the last row is centered */
    }
    .features-heading {
        font-size: 32px;
        font-weight: bold;
        color: #134084;
        margin-bottom: 40px;
    }
    
}

@media screen and (max-width: 500px) {
    .feature-item {
        flex: 0 1 100%; /* Single item takes full width and is centered */
    }
    .features-heading {
        font-size: 28px;
        font-weight: bold;
        color: #134084;
        margin-bottom: 40px;
    }
    
}
