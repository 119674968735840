/* 🔥 Make background white */

.root-section {
  text-align: center;
  padding: 80px 10%;
  /* background: linear-gradient(to right, #e0e2e7, #a6bae0); */
  color: #134084;
  background: rgba(239, 239, 239, 0.9); /* 0.9 reduces transparency */

}

.testimonial-section {
  display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 80px 0; */
  }
  .heading {
    font-size:50px;
    font-weight: bold;
    margin-bottom: 50px;
}
  /* 🔥 Swiper Container */
  .testimonial-swiper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 800px;
    max-width:1000px;
  }
  
  /* 🔥 Testimonial Card */
  .testimonial-card {
    background: #134084;
    color: white;
    padding: 30px;
    border-radius: 20px;
    text-align: center;
    transition: transform 0.4s ease, filter 0.4s ease;
    width: 400px;
    max-height: 400px; /* Start at 350px but grow if needed */
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden; /* 🔥 Prevents content from overflowing */
    justify-self: center;
  }

  .testimonial-text {
    font-size: 16px;
    color: white;
    max-height: 500px; /* Adjust height as needed */
    overflow-y: auto; /* Enable vertical scrolling */
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 8; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
}

/* 🔥 Customize Scrollbar (Optional) */
.testimonial-text::-webkit-scrollbar {
    width: 5px;
}

.testimonial-text::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 10px;
}

  
  /* 🔥 Center Slide (Bigger & Clear) */
  .swiper-slide-active {
    transform: scale(1) !important;
    filter: 0 !important;
    opacity: 2 !important;
}

/* 🔥 Other Slides - Slightly Blurred */
.swiper-slide:not(.swiper-slide-active) {
    filter: blur(2px) !important;
    opacity: 0.6 !important;
    transform: scale(0.5) !important;
}
.swiper-pagination{
  position: unset;
}
  /* 🔥 Responsive */
  @media screen and (max-width: 1024px) {
    .testimonial-card {
      width: 450px;
      height: auto;
      min-height: 400px; /* Start at 350px but grow if needed */

    }
    .heading {
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 50px;
  }
    .testimonial-swiper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 80%;
      /* max-width: 1000px; */
    }
    
    .swiper-slide-active {
      /* width: inherit !important; */
      transform: scale(1) !important;
      filter: 0 !important;
      opacity: 2 !important;
  }

  .swiper-slide:not(.swiper-slide-active) {
    filter: blur(2px) !important;
    opacity: 0.6 !important;
    transform: scale(0.5) !important;
}
    /* .swiper-slide-prev,
    .swiper-slide-next {
      transform: scale(0.8);
    } */
  }
  
  @media screen and (max-width: 768px) {
    .testimonial-swiper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 80%;
      /* max-width: 70%; */
    }

    .heading {
      font-size: 38px;
      font-weight: bold;
      margin-bottom: 50px;
  }
    .testimonial-card {
      width: 400px;
      height: auto;
      max-height: 400px; /* Start at 350px but grow if needed */

    }
  
    .swiper-button-prev, .swiper-button-next {
      position: absolute;
      top: var(--swiper-navigation-top-offset, 50%);
      width: 20px !important;
      height: 10px !important;
      margin-top: calc(0px -(var(--swiper-navigation-size) / 2));
      z-index: 10;
      cursor: pointer;
      display: flex
  ;
      align-items: center;
      justify-content: center;
      color: var(--swiper-navigation-color, var(--swiper-theme-color));
  }
  .swiper-slide-active {
    /* width: inherit !important; */
    transform: scale(1) !important;
    filter: 0 !important;
    opacity: 2 !important;
}

.swiper-slide:not(.swiper-slide-active) {
  filter: blur(2px) !important;
  opacity: 0.6 !important;
  transform: scale(0.5) !important;
}
  
  }

    @media screen and (max-width: 500px) {
      .testimonial-swiper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
        /* max-width: 70%; */
      }
      .testimonial-card {
        width: 300px;
        height: auto;
      }
      .heading {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 50px;
    }
  }
  