/* 🔥 Main Section */
.animated-bg-borrower-page {
    display: flex;
    margin: 0;
    background: rgba(239, 239, 239, 0.9); /* 0.9 reduces transparency */
    padding: 50px 50px;

}

/* 🔥 Section Wrapper */
.section-start-borrower {
    display: flex;
    width: 100%;
    /* background-color: #f5f5f5; */
}

/* 🔥 Grid Layout */
.benefit-section-borrower {
    display: grid;
    grid-template-columns: 1fr 0.8fr; /* Two equal parts */
    gap: 40px;
    align-items: center;
    width: 100%;
    justify-content: space-between;

}

/* 🔥 Left Content */
.lending-partners-borrower {
    border-radius: 10px;
    /* z-index: 7; */
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 5%;
    margin-bottom: 5%;
    margin-left: 0;
    /* padding-left: 10%; */
    /* padding-right: 8%; */
    display: flex;
    position: relative;
    overflow: hidden;
    max-width: 100%;
}

/* 🔥 Left Side Content */
.lp-text-content-borrower {
    max-width: 100%;
}

/* 🔥 Heading */
.lp-heading-borrower {
    font-size: 50px;
    font-weight: bold;
    color: #134084;
    margin-bottom: 30px;
}

/* 🔥 Features Container */
.lp-features-borrower {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

/* 🔥 Feature Box */
.lp-feature-borrower {
    display: flex;
    align-items: center;
    gap: 20px;
    mix-blend-mode: multiply; /* Removes white background */
    background-color: transparent;
}

/* 🔥 Feature Icon */
.lp-icon-borrower {
    width: 55px;
    height: 55px;
    flex-shrink: 0; /* Prevents icons from shrinking */
}

/* 🔥 Feature Title */
.lp-feature-title-borrower {
    font-size: 24px;
    font-weight: bold;
    color: #4c4b4b;
    margin-bottom: 5px;

}

/* 🔥 Feature Text */
.lp-feature-text-borrower {
    font-size: 16px;
    color: #666;
}

/* 🔥 CTA Button */

/* 🔥 Right Image */
.lp-image-borrower {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    max-width: 700px; /* Ensures a large, balanced image */
    margin: 0 auto;
}

.lp-image-borrower img {
    width: 100%;
    max-width: 500px;
    height: auto;
}

/* 🔥 Responsive Design Fixes */
/* @media screen and (max-width: 1500px) {
    .lending-partners-borrower {
        padding-right: 10%;
        max-width: 600px;
    }
} */

@media screen and (max-width: 1400px) {
    .lending-partners-borrower {
        /* padding-left: 6%; */
        max-width: 600px;
    }

    .lp-image-borrower {
        max-width: 650px;
    }
}

@media screen and (max-width: 1200px) {
    .lending-partners-borrower {
        max-width: 550px;
    }

    .lp-image-borrower {
        max-width: 600px;
    }
}

@media screen and (max-width: 1024px) {
    .benefit-section-borrower {
        display: flex;
        flex-direction: column; /* Moves image below text */
        align-items: center;
        text-align: center;
        gap: 0px; /* Adds space for better readability */

    }

    .lending-partners-borrower {
        max-width: 600px;
        margin: 5% auto;
        /* padding: 0 8%; */
        align-items: center;
    }

    .lp-image-borrower {
        order: 2; /* Ensures image appears last */
        width: 100%;
        max-width: 600px;
        margin-top: 10px;

        /* margin-top: 20px; */
    }

    .lp-image-borrower img {
        width: 100%;
        max-width: 600px;
    }

    .lp-feature-borrower {
        flex-direction: row; /* Keeps icons & text in one line */
        justify-content: flex-start;
        align-items: center;
        text-align: left;
        /* gap: 15px; */
    }

    .lp-feature-title-borrower,
    .lp-feature-text-borrower {
        text-align: left;
    }

    .lp-heading-borrower {
        font-size:40px;
        margin-bottom: 25px;

    }
}

@media screen and (max-width: 768px) {
    .lp-heading-borrower {
        font-size: 38px;
    }

    .lp-feature-title-borrower
     {
        font-size: 18px;
    }

       .lp-feature-text-borrower {
        font-size: 15px;
    }

    .lp-feature-borrower {
        flex-direction: row;
        align-items: center;
        text-align: left;

        /* gap: 12px; */
    }

    .lp-image-borrower {
        width: 100%;
        max-width: 500px;
        /* margin-top: 10px; */
    }

    .lp-image-borrower img {
        width: 100%;
        max-width: 500px;
    }
}

/* 🔥 Extra Small Screens */
@media screen and (max-width: 480px) {
    .lp-feature-borrower {
        flex-direction: row;
        align-items: center;
        text-align: left;
        /* gap: 10px; */
    }

    .lp-image-borrower {
        width: 100%;
        max-width: 450px;
        /* margin-top: 5px; */
    }

    .lp-feature-title-borrower,
    .lp-feature-text-borrower {
        text-align: left;
    }
    .lp-image-borrower img {
        width: 100%;
        max-width: 450px;
    }
    .lp-heading-borrower {
        font-size: 32px;
    }
}

/* @media screen and (max-width: 1024px) {
    .benefit-section-borrower {
        grid-template-columns: 1fr;
        text-align: center;
    }
    .lending-partners-borrower {
        padding: 0 10%;
        max-width: 600px;
        margin: 5% auto;
        align-items: center;
    }

    .lp-heading-borrower {
        font-size: 40px;
        margin-bottom: 25px;
    }

    .lp-text-content-borrower {
        max-width: 100%;
    }

    .lp-feature-borrower {
        flex-direction: row;
        justify-content: flex-end;
        text-align: left;
        gap: 15px;
    }

    .lp-feature-title-borrower,
    .lp-feature-text-borrower {
        text-align: left;
    }
    .lp-image-borrower {
        max-width: 550px;
        margin-top: 10px;
    }
}

@media screen and (max-width: 768px) {
    .lp-heading-borrower {
        font-size: 34px;
    }

    .lp-feature-borrower {
        flex-direction: row;
        justify-content: flex-start;
        text-align: left;
        gap: 12px;
    }

    .lp-feature-title-borrower {
        font-size: 18px;
    }

    .lp-feature-text-borrower {
        font-size: 15px;
    }

    .lp-image-borrower {
        width: 100%;
        max-width: 500px;
        margin-top: 10px;
    }

    .lp-image-borrower img {
        width: 100%;
        max-width: 480px;
    }
}

@media screen and (max-width: 480px) {
    .lp-heading-borrower {
        font-size: 28px;
    }

    .lp-feature-borrower{
        flex-direction: row; 
        gap: 10px;
        align-items: center;
    }

    .lp-feature-title-borrower,
    .lp-feature-text-borrower {
        text-align: left;
    }

    .lp-icon-borrower {
        width: 45px;
        height: 45px;
    }

    .lp-image-borrower {
        width: 100%;
        max-width: 450px;
        margin-top: 5px;
    }

    .lp-image-borrower img {
        width: 100%;
        max-width: 420px;
    }
} */
